.email {
  width: 60%;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 1px solid #000;
  outline: none;
}

.email-title {
  font-weight: bold;
}
