.card-title {
  color: white;
  font-weight: bold;
  text-transform: uppercase;
}

.total-title {
  background-color: var(--green);
}

.children-title {
  background-color: black;
}
